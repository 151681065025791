import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../layout/navbar';
import './../style/gearUp.css'
import axios from 'axios';

export const GearUp = () => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        axios.get('https://727instrumental.org/backend/public/api/merchandise')
        .then(response => {
            setItems(response.data);
        })
        .catch(error => {console.error(("API CRASHED !", error));
        });
    },[]);
    const navigate = useNavigate();

    const goToProduct = (id) => {
      navigate(`/product/${id}`); // Navigate to the product details page
    };
  return (
    <div>
        <Navbar />

        <h1 style={{color: "#fff"}}>GearUp With Our Latest Merchandise</h1>

        

        <div className='productrows'>

        <div className='mainShopContainer'>


        {items.map((item, index) => {

const productImages = item.image_url ? item.image_url.split(",") : ['default.jpg'];

return (
                    <div className='Blocks4'>
                                
                                <div className='productimgblock'>
                                                <img src={item.image_url 
                                                    ? `https://727instrumental.org/backend/public/products/${productImages[0]}` 
                                                    : 'https://727instrumental.org/backend/public/products/default.jpg'} 
                                                alt={item.name || "Default Product"} 
                                                className="itemImage" 
                                                />
                                </div>
                                <div className='itemname'><span>{item.name} </span></div>
                                <div className='footerstrip'>
  <div className='itemPrice'>
    <p>${item.price}</p>
  </div>
  <div className='shopBtn'>
    <input type='submit' value="+"  onClick={() => goToProduct(item.id)}/>
  </div>
</div>
                    
                </div>
);
})}



            
            </div>
        </div>
       
        <h2 style={{color: "#fff"}}></h2>
          
        

        

    </div>
  )
}